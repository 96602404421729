@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.header {
  background: $color-primary;
  backdrop-filter: blur(6px);
  height: $header-height-mobile;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  text-align: left;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #eceff1;

  display: flex;
  align-items: center;

  @include breakpoint($breakpoint-medium) {
    height: $header-height;
  }
}

.headerContent {
  max-width: 100%;
  width: $content-container-width;
  padding: 0 0.75rem;
  margin: 0 auto;
  min-height: 1.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  h1 {
    font-size: 1.5rem;
    padding: 0 0.75rem;
    text-align: center;

    @include breakpoint($breakpoint-medium, 'max') {
      font-size: 1rem;
    }
  }

  @include breakpoint($content-container-width) {
    padding: 0 0.5rem;
  }
}

.headerTitle {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.actionLinkIcon {
  width: 1.75rem;
  height: 1.75rem;
}

.actionLinkText {
  height: 1.75rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  padding-top: 1px;

  @include breakpoint($breakpoint-medium, 'max') {
    font-size: 1rem;
    padding-top: 0;
  }

  @include breakpoint($breakpoint-small, 'max') {
    display: none;
  }
}

.actionLinkPlaceholder {
  min-width: 45px;

  @include breakpoint($breakpoint-medium) {
    min-width: 70px;
  }
}
